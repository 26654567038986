<!-- src/views/Success.vue -->

<template>
    <div class="success-page">
      <h1>Pagamento Realizado com Sucesso!</h1>
      <p>Obrigado pela sua compra. Seu pedido foi processado com sucesso.</p>
      <button class="payment-button" @click="goToUserArea">Ir para a Área do Usuário</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SucessoPage',
    methods: {
      goToUserArea() {
        this.$router.push('/user-area'); // Altere o caminho para o URL da área do usuário
      },
    },
  };
  </script>
  
  <style scoped>
  .success-page {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  
<template>
  <div id="app">
    <div class="site-container">
    <NavBar />  <!-- Exemplo de uma barra de navegação -->
    <router-view />  <!-- Renderiza o conteúdo da página atual (Home ou outras páginas) -->
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
};
</script>

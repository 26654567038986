<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-12 col-lg-5">
      <div class="ride-booking">
        <div class="booking-form">
          <div class="mb-3">
            <h1>Transporte pequenas cargas com praticidade:</h1>
          </div>

          <div class="row g-2" style="border:1px solid #efefef;border-radius: 10px;padding:10px;margin-bottom: 20px">
            <div class="flex-label">
              <span class="icon">🏁</span>
              <h4>Local de Retirada</h4>
            </div>
            <div class="col-12 col-lg-12">
              <div class="input-group">
                <input type="text" placeholder="Digite o endereço de retirada" ref="pickupInput" v-model="pickup" />
              </div>
            </div>
            <div class="col-12 col-lg-12">
              <div class="input-group">
                <input type="text" placeholder="Apto, Bloco, etc..." v-model="pickupComplemento" />
              </div>
            </div>
            <div class="row g-1">
              <h4>Quando?</h4>
              <div class="col-6 col-lg-8">
                <div class="input-group">
                  <input type="date" v-model="pickupDate" />
                </div>
              </div>
              <div class="col-6 col-lg-4">
                <div class="input-group">
                  <select v-model="pickupTime">
                    <option v-for="time in timeOptions" :key="time.value" :value="time.value">
                      {{ time.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row g-2" style="border:1px solid #efefef;border-radius: 10px;padding:10px">
            <div class="flex-label">
              <span class="icon">📍</span>
              <h4>Local de Entrega</h4>
            </div>
            <div class="col-12 col-lg-8">
              <div class="input-group">
                <input type="text" placeholder="Digite o endereço de entrega" ref="dropoffInput" v-model="dropoff" />
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="input-group">
                <input type="text" placeholder="Apto, Bloco, etc..." v-model="dropoffComplemento" />
              </div>
            </div>
          </div>

          <button class="price-button" @click="onCheckPrices">Ver preços</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7 p-3 d-flex justify-content-end">
      <div id="map" class="map"></div>
    </div>
  </div>
</template>

<script>
/* global google */

export default {
  name: "RideBooking",
  data() {
    return {
      pickup: "",
      dropoff: "",
      pickupComplemento: "",
      dropoffComplemento: "",
      pickupDate: "",
      pickupTime: "",
      pickupDateFormatted: "",
      timeOptions: [],
      map: null,
      directionsService: null,
      directionsRenderer: null,
      autocompletePickup: null,
      autocompleteDropoff: null,
      distanceInKm:0,
    };
  },
  methods: {
    loadGoogleMaps() {
  if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
    // API do Google Maps já carregada, apenas inicializar o mapa e autocomplete
    this.initializeMap();
    this.initializeAutocomplete();
    return;
  }

  const self = this;
  window.initMap = function () {
    self.initializeMap();
    self.initializeAutocomplete();
  };

  if (typeof google === 'undefined') {
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API;

    // Log da chave para verificar se está carregando corretamente
    //console.log("Chave da API do Google Maps:", apiKey);

    if (!apiKey) {
      console.error("Erro: Chave da API do Google Maps não encontrada. Verifique a variável de ambiente.");
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
},
    initializeMap() {
      if (typeof google === 'undefined') return;

      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -22.9068, lng: -43.1729 },
        zoom: 13,
      });
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
    },
    initializeAutocomplete() {
      if (typeof google === 'undefined') return;

      this.autocompletePickup = new google.maps.places.Autocomplete(this.$refs.pickupInput, {
        types: ["geocode"],
      });
      this.autocompletePickup.addListener("place_changed", () => {
        const place = this.autocompletePickup.getPlace();
        if (place.geometry) {
          this.pickup = place.formatted_address;
        }
      });

      this.autocompleteDropoff = new google.maps.places.Autocomplete(this.$refs.dropoffInput, {
        types: ["geocode"],
      });
      this.autocompleteDropoff.addListener("place_changed", () => {
        const place = this.autocompleteDropoff.getPlace();
        if (place.geometry) {
          this.dropoff = place.formatted_address;
          this.calculateRoute();
        }
      });
    },
    calculateRoute() {
      if (!this.pickup || !this.dropoff || typeof google === 'undefined') return;

      const request = {
        origin: this.pickup,
        destination: this.dropoff,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsRenderer.setDirections(result);

          // Obtém e converte a distância para km
          const distanceInMeters = result.routes[0].legs[0].distance.value;
          const distanceInKm = (distanceInMeters / 1000).toFixed(2);
          console.log(`Distância: ${distanceInKm} km`);
        } else {
          alert("Não foi possível calcular a rota");
        }
      });
    },
    onCheckPrices() {
      this.$router.push({
        name: "GoPage",
        query: {
          pickup: this.pickup,
          pickupComplemento: this.pickupComplemento,
          pickupDate: this.pickupDate,
          pickupTime: this.pickupTime,
          dropoff: this.dropoff,
          dropoffComplemento: this.dropoffComplemento,
          pickupDateFormatted: this.pickupDateFormatted,
          tripDistance: this.distanceInKm,
        },
      });
    },
    setTodayDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();

      this.pickupDate = `${year}-${month}-${day}`;
      this.pickupDateFormatted = `${day}/${month}`;
    },
    generateTimeOptions() {
      const options = [];
      const startHour = 0;
      const endHour = 24;

      for (let hour = startHour; hour < endHour; hour++) {
        for (let minutes of [0, 30]) {
          const time = new Date();
          time.setHours(hour, minutes, 0, 0);

          const timeLabel = time.toTimeString().slice(0, 5);
          options.push({
            label: timeLabel,
            value: timeLabel,
          });
        }
      }

      const currentTime = new Date().toTimeString().slice(0, 5);
      this.timeOptions = options;
      const closestOption = options.find(option => option.value >= currentTime);
      this.pickupTime = closestOption ? closestOption.value : options[0].value;
    },
  },
  mounted() {
    this.loadGoogleMaps();
    this.setTodayDate();
    this.generateTimeOptions();
  },
};
</script>

<style scoped>
.ride-booking {
  display: flex;
  gap: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
}

.input-group .icon {
  margin-right: 10px;
}

span.icon {
  margin-right: 5px;
}

.input-group input,
.input-group select {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  min-height: 25px;
  font-size: 14px;
}

.flex-label {
  display: flex;
}

.price-button {
  width: 100%;
  padding: 10px;
  background-color: #FF6038;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  min-height: 55px;
  margin-top: 12px;
}

.map {
  width: 90%;
  min-height: 65vh;
  height: 100%;
  border-radius: 10px;
}


@media (max-width: 768px) {
  .map  {
    width: 100%;
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import router from './router'; // Importa o router
import './global.css'; // Importa o CSS global

const app = createApp(App);

app.use(router); // Usa o router
app.mount('#app');

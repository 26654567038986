<template>
    <section class="hero-section">
        <div class="container">
            <RideBooking />
            <ComoCarregar />
        </div>
    </section>
            <RodaPe />
        
</template>

<script>
import RideBooking from '@/components/RideBooking.vue';
import ComoCarregar from '@/components/ComoCarregar.vue';
import RodaPe from '@/components/RodaPe.vue';

export default {
    name: 'HomePage',
    components: {
        RideBooking,
        ComoCarregar,
        RodaPe,
    },
};
</script>

<style scoped>
.hero-section {
    padding: 32px 16px;
}

.container {
    max-width: 1280px;
    width: 100%;
}

@media (max-width: 768px) {
    .container {
        max-width: 90vw;
    }
}
</style>

<template>
    <div class="section">
        <div class="como-carregar">
            <div class="mb-4">
                <h2>Como Carregar?</h2>
                <p>Aprenda a carregar e gerenciar suas reservas de forma fácil e rápida.</p>
            </div>
            <div class="border-box">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="third-block-content">
                            <span class="emoji">⏰</span>
                            <h3>1. Informe o Local e o Horário</h3>
                            <p>Digite o endereço de retirada e entrega, além do melhor horário para sua viagem. Simples
                                e direto, garantindo flexibilidade para você.</p>

                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                    <div class="third-block-content">
                        <span class="emoji">🗳️</span>
                        <h3>2. Detalhe o Que Será Transportado</h3>
                        <p>Especifique os itens que precisam ser transportados e outros detalhes importantes. Dessa
                            forma, garantimos que tudo seja feito com segurança e cuidado.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                <div class="third-block-content">
                    <span class="emoji">🚚</span>
                    <h3>3. Aguarde a Confirmação</h3>
                    <p>Nossa equipe de carregadores aceitará sua solicitação rapidamente, e você será notificado
                        assim que tudo estiver pronto para o transporte.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Adicione aqui o conteúdo que você deseja exibir -->
    </div>
    </div>
</template>

<script>
export default {
    name: 'ComoCarregar',
    data() {
        return {
            // Defina aqui os dados necessários para o componente
        };
    },
    methods: {
        // Adicione métodos para o comportamento do componente, se necessário
    },
    mounted() {
        // Código a ser executado quando o componente é montado
    }
};
</script>

<style scoped>
.section {
    padding: 64px 0px;
}

.third-block-content {
    padding: 25px;
}

.border-box {
    border: solid 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 20px;
}

.como-carregar {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
}

.como-carregar h2 {
    font-size: 24px;
    margin-bottom: 8px;
}

.como-carregar p {
    font-size: 16px;
    color: #555;
}

.emoji {
    font-size:32px;
    margin-bottom: 15px;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .third-block-content {
    padding: 15px;
}
}
</style>

<template>
    <footer class="footer">
        <div class="footer-container">
            <!-- Logo e Link para Central de Ajuda -->


            <!-- Links principais organizados em colunas -->
            <div class="footer-links">
                <div class="row d-flex justify-content-between">
                    <div class="col-12 col-lg-3">
                        <div class="footer-top">
                            <img src="../assets/Group 5.png" alt="" width="150px;">
                            <!-- <a href="#" class="help-link">Acesse a Central de Ajuda</a> -->
                        </div>
                    </div>
                    <!-- <div class="col-12 col-lg-3">
                        <div class="footer-column">
                            <h3>Empresa</h3>
                            <ul>
                                <li><a href="#">Quem somos</a></li>
                                <li><a href="#">O que oferecemos</a></li>
                                <li><a href="#">Newsroom</a></li>
                                <li><a href="#">Investidores</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Carreiras</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="footer-column">
                            <h3>Produtos</h3>
                            <ul>
                                <li><a href="#">Viajar</a></li>
                                <li><a href="#">Dirigir</a></li>
                                <li><a href="#">Fazer entregas</a></li>
                                <li><a href="#">Faça um pedido</a></li>
                                <li><a href="#">Frete Leve para Empresas</a></li>
                                <li><a href="#">Frete Leve Freight</a></li>
                                <li><a href="#">Gift cards</a></li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-12 col-lg-3">
                        <div class="footer-column">
                            <h3>Contato</h3>
                            <ul>
                                <li><a href="https://instagram.com/freteleve.com.br">Instagram</a></li>
                                <li><a href="#">atendimento@freteleve.com.br</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>




            </div>

            <!-- Informações de contato e redes sociais -->
            <div class="footer-bottom">
                <div class="footer-info">
                    <p>Frete Leve Technologies Inc. | ASTX Tecnologia Ltda</p>
                    <p>CNPJ: 32.749.302-0001/53</p>
                </div>
                <div class="social-links">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-youtube"></i></a>
                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                </div>
                <div class="app-links">
                    <!-- <a href="#"><img src="path-to-google-play.png" alt="Google Play"></a>
            <a href="#"><img src="path-to-app-store.png" alt="App Store"></a> -->
                </div>
            </div>

            <!-- Links de idioma, local e política -->
            <div class="footer-meta">
                <div class="meta-links">
                    <a href="#">Português (Brasil)</a>
                </div>
                <div class="policy-links">
                    <a href="#">Privacidade</a>
                    <a href="#">Acessibilidade</a>
                    <a href="#">Termos</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style scoped>
.footer {
    background-color: #FF6038;
    color: #fff;
    padding: 40px;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.footer-top h2 {
    font-size: 24px;
}

.help-link {
    color: #fff;
    text-decoration: underline;
}

.footer-links {
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: 30px;
}

.footer-column h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column li {
    margin-bottom: 5px;
}

.footer-column a {
    color: #fff;
    text-decoration: none;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid white;
    padding-top: 20px;
    margin-top: 30px;
}

.footer-info {
    font-size: 12px;
    line-height: 1.5;
}

.social-links a {
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
}

.app-links img {
    height: 30px;
    margin-right: 10px;
}

.footer-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid white;
    font-size: 14px;
}

.meta-links a,
.policy-links a {
    color: #fff;
    text-decoration: none;
    margin-right: 15px;
}
</style>
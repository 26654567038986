<template>
    <section class="hero-section agente-de-carga">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="formulario-captura">
                        <h1>Formulário de Captura</h1>
                        <form @submit.prevent="submitForm">
                            <!-- Campos de input do formulário -->
                            <div>
                                <label for="nomeCompleto">Nome Completo:</label>
                                <input type="text" id="nomeCompleto" v-model="formData.nomeCompleto" required />
                            </div>
                            <div>
                                <label for="email">Email:</label>
                                <input type="email" id="email" v-model="formData.email" required />
                            </div>
                            <div>
                                <label for="comprovanteEndereco">Comprovante de Endereço (PDF ou Imagem):</label>
                                <input type="file" id="comprovanteEndereco"
                                    @change="handleFileChange($event, 'comprovanteEndereco')" accept=".pdf, image/*"
                                    required />
                            </div>
                            <div>
                                <label for="frenteCnh">Frente da Carteira de Motorista:</label>
                                <input type="file" id="frenteCnh" @change="handleFileChange($event, 'frenteCnh')"
                                    accept="image/*" required />
                            </div>
                            <div>
                                <label for="versoCnh">Verso da Carteira de Motorista:</label>
                                <input type="file" id="versoCnh" @change="handleFileChange($event, 'versoCnh')"
                                    accept="image/*" required />
                            </div>
                            <div>
                                <label for="cpfCnpj">CPF/CNPJ:</label>
                                <input type="text" id="cpfCnpj" v-model="formData.cpfCnpj" required />
                            </div>
                            <div>
                                <label for="documentoCrv">CRV (Documento do Veículo):</label>
                                <input type="file" id="documentoCrv" @change="handleFileChange($event, 'documentoCrv')"
                                    accept=".pdf, image/*" required />
                            </div>

                            <button type="submit" class="cta-button">Enviar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <RodaPe />



</template>
<script>

import RodaPe from '@/components/RodaPe.vue';
import axios from 'axios';

export default {
    name:'CadastroAgente',
    components: {
        RodaPe,
    },
  data() {
    return {
      formData: {
        nomeCompleto: '',
        email: '',
        comprovanteEndereco: null,
        frenteCnh: null,
        versoCnh: null,
        cpfCnpj: '',
        documentoCrv: null
      }
    };
  },
  methods: {
    handleFileChange(event, field) {
      const file = event.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB

      if (file && file.size > maxSize) {
        alert('O arquivo não pode ser maior que 5 MB.');
        event.target.value = null; // Limpa o input
      } else {
        this.formData[field] = file;
      }
    },
    async submitForm() {
      try {
        const formData = new FormData();
        formData.append('nomeCompleto', this.formData.nomeCompleto);
        formData.append('email', this.formData.email);
        formData.append('comprovanteEndereco', this.formData.comprovanteEndereco);
        formData.append('frenteCnh', this.formData.frenteCnh);
        formData.append('versoCnh', this.formData.versoCnh);
        formData.append('cpfCnpj', this.formData.cpfCnpj);
        formData.append('documentoCrv', this.formData.documentoCrv);

        // await axios.post('http://localhost:3000/api/send-email', formData, {
        await axios.post('/api/send-email', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        alert('Formulário enviado com sucesso!');
      } catch (error) {
        console.error('Erro ao enviar o formulário:', error);
        alert('Erro ao enviar o formulário.');
      }
    }
  }
};
</script>

<style scoped>
.hero-section {
    padding: 62px 16px;
}

.agente-de-carga {
    background-image: url('../assets/hero-trucker-image.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.centralized {
    text-align: center;
}

.formulario-captura {
    color: white;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
}

.formulario-captura label {
    display: block;
    margin-bottom: 5px;
}

.formulario-captura input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


button:hover {
    background-color: #218838;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import SucessoPage from '@/views/SucessoPage.vue';
import Home from '../views/Home.vue'; // A página principal (Home)
import GoPage from '@/views/GoPage.vue';
import CadastroAgente from '@/views/CadastroAgente.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },       // Rota para Home
  { path: '/successo', name: 'SucessoPage', component: SucessoPage },
  { path: '/cadastro-agente', name: 'CadastroAgente', component: CadastroAgente },
  { path: '/go', name: 'GoPage', component: GoPage, props: route => ({
    pickup: route.query.pickup,
    pickupComplemento: route.query.pickupComplemento,
    pickupDate: route.query.pickupDate,
    pickupDateFormatted: route.query.pickupDateFormatted,
    pickupTime: route.query.pickupTime,
    dropoff: route.query.dropoff,
    dropoffComplemento: route.query.dropoffComplemento,
  }), 
}  // Rota para About
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
